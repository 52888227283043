
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './App.css'
import React, { useEffect, useState } from "react";

function App() {
  const availWidth = window.screen.availWidth
  const [screenWidth, setScreenWidth] = useState(availWidth)
  const sliderDesktop = [
    { "img": "https://firebasestorage.googleapis.com/v0/b/karvdesign-8a1f7.appspot.com/o/karv-desktop-image%2FKARV_D.webp?alt=media&token=6d7879df-12c9-4f5c-86a5-3b37b44ff38c" },
    { "img": "https://firebasestorage.googleapis.com/v0/b/karvdesign-8a1f7.appspot.com/o/karv-desktop-image%2FKARV_D2.webp?alt=media&token=3dec2e02-a31c-44c1-8c17-4e4ff7a86429" },
    { "img": "https://firebasestorage.googleapis.com/v0/b/karvdesign-8a1f7.appspot.com/o/karv-desktop-image%2FKARV_D3.webp?alt=media&token=91f3d3cf-e5f8-446e-9799-9c8f9f9f679f" },
    { "img": "https://firebasestorage.googleapis.com/v0/b/karvdesign-8a1f7.appspot.com/o/karv-desktop-image%2FKARV_D4.webp?alt=media&token=0ca4b82e-5ca9-4ba1-aae0-e9dc318c4f4d" }
  ];



  useEffect(() => {

    setInterval(() => {
      // console.log("karv")
    }, 1000);

  }, [availWidth])


  return (
    <React.Fragment>
      {screenWidth > 780 ?
        <Carousel showThumbs={false} autoPlay={true} dynamicHeight={true} emulateTouch={true} infiniteLoop={true} showStatus={false} >
          {sliderDesktop?.map((slide, i) => (
            <div key={slide}>
              <img src={slide?.img} alt="karv design" className="slide-img" />
            </div>
          ))}
        </Carousel> :
        <div className="mobile-tab-screen">
          {sliderDesktop?.map((slide, i) => (
            <div key={slide}>
              {/* <img src="..." class="img-fluid" alt="Responsive image"></img> */}
              <img src={slide?.img} alt="karv design" className="img-thumbnail slide-img mx-auto d-block" />
            </div>
          ))}
        </div>}



    </React.Fragment>
  );
}

export default App;
